import styled from "styled-components";

export const DetailsLayout = styled.div`
	margin: 75px 0 50px 0;
	display: flex;
	flex-direction: column;
	gap: 7px;

	.general {
		display: grid;
		grid-template-columns: repeat(7, 1fr);
		grid-gap: 7px;
		height: 70vh;
	}

	.card {
		border-radius: 5px;
	}

	.info-card {
		height: 5vh;
		min-height: 47px;
		grid-column: 1 / -1;
		display: flex;
		gap: 7px;
		align-items: center;

		.tabs,
		.name-card {
			height: 100%;
			width: 100%;
		}

		.name-card {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 10px;
			box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
			border-radius: 5px;

			.name-card-title {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: ${(props) => props.theme.white};
				border-radius: 5px;
			}
		}

		.tabs {
			display: flex;
			justify-content: space-around;
			align-items: center;
			text-align: center;
			gap: 7px;

			div {
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;
				background-color: ${(props) => props.theme.primary};
				color: ${(props) => props.theme.tableheadfontcolor};
				border-radius: 5px;
				cursor: pointer;
				box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
			}

			.active {
				background-color: ${(props) => props.theme.darkerprimary};
				color: ${(props) => props.theme.tableheadfontcolor};
			}
		}
	}

	.metrics-card {
		display: flex;
		height: 90px;
		gap: 7px;

		.metrics-card-status {
			position: relative;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 35%;
			gap: 7px;
			box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
			border-radius: 5px;

			.metrics-card-title {
				position: absolute;
				top: 0;
				left: 0;
				padding: 15px;
				font-weight: 700;
				text-align: start;
				color: white;
			}
			div {
				height: 100%;
				width: 100%;
				text-align: center;
				display: flex;
				justify-content: center;
				align-items: end;
				padding: 0 15px;
				padding-bottom: 25px;
				background-color: ${(props) => props.theme.white};
				border-radius: 5px;
			}
		}

		.metrics-card-month,
		.metrics-card-lastmonth,
		.metrics-card-24 {
			background-color: ${(props) => props.theme.white};
			width: 100%;
			padding: 15px;
			border-radius: 5px;
			display: flex;
			justify-content: space-between;
			box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

			.metrics-card-title {
				font-weight: 700;
				text-align: start;
			}
			.metrics-card-stats {
				display: flex;
				align-items: end;
				gap: 15px;
			}

			div {
				text-align: end;
			}
		}
	}

	.action-card {
		display: flex;
		flex-direction: column;
		gap: 7px;
		grid-column: 1 / 3;

		.action {
			display: flex;
			align-items: center;
			gap: 7px;

			button {
				width: 100%;
			}
		}
		.scheme-logs-card {
			display: flex;
			flex-direction: row;
			gap: 7px;
		}

		.pump-status {
			position: relative;
			overflow: auto;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			width: 100%;
			height: 100%;
			background-color: ${(props) => props.theme.white};
			border-radius: 5px;
			padding: 20px 10px;
			white-space: normal;
			word-wrap: break-word;
			box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

			.pump-status-register {
				margin-bottom: 5px;
			}

			.refresh-button {
				position: absolute;
				top: 5px;
				right: 5px;
			}
			span {
				font-weight: 700;
				display: inline-block;
				width: 60%;
				text-align: right;
				margin-right: 10px;
			}
		}

		.status {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-around;
			height: 100%;
			width: 50%;
			gap: 5px;
			background-color: ${(props) => props.theme.white};
			padding: 10px;
			border-radius: 5px;
			font-size: 14px;
			box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

			.active-schedule {
				color: #01a701;
			}

			.bold {
				font-weight: 700;
			}

			li {
				margin-bottom: 5px;
			}

			div,
			ol {
				text-align: center;
			}
			ul {
				margin-left: 20px;
			}
		}

		.scheme-card {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 50%;
			box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
			border-radius: 5px;
			overflow: hidden;
			::before {
				content: "";
				position: absolute;
				top: 0;
				bottom: 20%;
				left: 0;
				right: 0;
				background-color: ${(props) => props.theme.white};
				z-index: -1;
			}
			::after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 84%;
				background-color: #463b3b;
				border-bottom-left-radius: 5px;
				border-bottom-right-radius: 5px;
				z-index: -1;
			}
		}
	}

	.map {
		grid-column: 3 / -1;
		box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	}

	.map-container {
		position: relative;
		width: 100%;
		height: 100%;
		padding: 20px;
		background-color: ${(props) => props.theme.white};
		border-radius: 5px;
	}
	.select-layers-button {
		position: absolute;
		top: 30px;
		right: 30px;
		padding: 1px 4px;
		z-index: 2;
		background-color: white;
		border: 1px solid transparent;
		border-radius: 2px;

		:hover {
			border: 1px solid black;
		}
	}

	.select-layers {
		position: absolute;
		display: flex;
		flex-direction: column;
		padding: 10px 15px;
		background-color: white;
		top: 53px;
		right: 30px;
		border-radius: 5px;
		z-index: 1;
		color: #3f3f3f;

		input {
			margin-right: 5px;
		}
	}
	.table-container {
		position: relative;
		display: flex;
		flex-direction: column-reverse;
		padding-top: 20px;
		padding: 20px;
		background-color: ${(props) => props.theme.white};
		border-radius: 5px;

		.logs-button {
			position: absolute;
			top: 0;
			right: 0;
			padding: 5px 15px;
			min-width: 120px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 10px;
			background-color: ${(props) => props.theme.white};
			border-radius: 5px;
			cursor: pointer;
			z-index: 2;
		}

		.table-title {
			position: absolute;
			top: 153px;
			left: 0%;
			padding: 0 25px;
			background-color: ${(props) => props.theme.white};
			z-index: 2;
		}

		.search-input {
			padding: 13px 10px;
			border: none;
			outline: none;
			border-radius: 5px;
			background-color: ${(props) => props.theme.secondary};
		}

		.datepicker-container {
			display: flex;
			flex-wrap: wrap;
			gap: 15px;
			margin-bottom: 20px;
		}

		.react-daterange-picker__wrapper {
			border-radius: 5px;
		}
		.react-daterange-picker__calendar {
			z-index: 2;
		}
	}
	.workhistory-container {
		position: relative;
		width: 100%;
		text-align: center;
		height: 75vh;
		margin-bottom: 15px;

		.react-daterange-picker {
			position: absolute;
			top: 15px;
			left: 10px;
			background-color: ${(props) => props.theme.white};
			margin-bottom: 10px;
			z-index: 5;
		}

		.chart-container {
			position: relative;
			padding-top: 30px;
			height: 45%;
			min-height: 300px;
			margin-bottom: 7px;
			background-color: ${(props) => props.theme.white};
			border-radius: 5px;

			.loading {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}

	.settings-container {
		position: relative;
		display: flex;
		flex-direction: column-reverse;
		padding: 20px;
		background-color: ${(props) => props.theme.white};
		border-radius: 5px;

		.actions {
			display: flex;
			flex-wrap: wrap;
		}

		.advanced-settings-table-container {
			display: flex;
			gap: 7px;

			div:nth-child(1) {
				flex: 2;
			}

			div:nth-child(2) {
				flex: 1;
			}
		}

		h2 {
			position: absolute;
			top: 100px;
			left: 0%;
			padding: 0 25px;
			background-color: ${(props) => props.theme.white};
			text-align: center;
			z-index: 2;
		}

		.modal-overlay {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.5);
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 15;

			.modal-content {
				background-color: ${(props) => props.theme.white};
				padding: 20px;
				border-radius: 8px;
				min-width: 400px;
				max-width: 700px;
				display: flex;
				flex-direction: column;
				gap: 10px;

				.modal-settings {
					display: flex;
					justify-content: space-around;

					div {
						display: flex;
						flex-direction: column;
					}
					input {
						width: 100px;
						background-color: ${(props) => props.theme.secondary};
						color: ${(props) => props.theme.fontcolor};
					}
				}

				.modal-actions {
					display: flex;
					justify-content: flex-end;
					margin-top: 20px;
				}

				.modal-actions {
					.cancel {
						margin-right: 10px;
					}
					.add {
						margin-left: 10px;
					}
				}
			}
		}

		.rdt_TableCell {
			display: flex;
			align-items: center;
		}
		.advanced-button {
			position: absolute;
			top: 0;
			right: 0;
			padding: 5px 15px;
			min-width: 120px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 10px;
			background-color: ${(props) => props.theme.white};
			border-radius: 5px;
			cursor: pointer;
			z-index: 2;
		}

		input {
			padding: 11px 10px;
			width: 400px;
			border: none;
			outline: none;
			border-radius: 5px;
			color: ${(props) => props.theme.fontcolor};
			background-color: ${(props) => props.theme.white};
		}

		.advanced-input {
			padding: 11px 10px;
			width: 100%;
			border: none;
			outline: none;
			border-radius: 5px;
			color: ${(props) => props.theme.fontcolor};
			background-color: ${(props) => props.theme.white};
		}

		.select {
			width: 400px;
		}
	}

	.action-button {
		width: 222px;
		margin-bottom: 20px;
	}

	.rdt_Table {
		padding-top: 56px;
		border-top: 1px solid rgba(0, 0, 0, 0.12);
	}

	.commands-main {
		.rdt_Table {
			padding: 20px;
			border-radius: 5px;
		}
	}

	.commands-main {
		display: flex;
		flex-direction: column;
		gap: 10px;
		.commands-container {
			display: flex;
			gap: 10px;

			.commands-card {
				display: flex;
				flex-direction: column;
				gap: 20px;
				width: 100%;
				background-color: ${(props) => props.theme.white};
				border-radius: 5px;
				padding: 20px;

				div {
					display: flex;
					align-items: center;
					gap: 20px;

					input {
						padding: 13px 10px;
						border: none;
						outline: none;
						border-radius: 5px;
						color: ${(props) => props.theme.fontcolor};
						background-color: ${(props) => props.theme.secondary};
					}

					p {
						min-height: 20px;
						color: ${(props) => props.theme.danger};
					}

					input,
					div,
					p {
						width: 100%;
					}
				}
			}
		}
		.additional-commands {
			display: flex;
			gap: 20px;

			.placeholder,
			.additional-commands-actions {
				width: 100%;
				padding: 20px;
			}
			.additional-commands-actions {
				display: flex;
				gap: 20px;
				background-color: ${(props) => props.theme.white};
				border-radius: 5px;

				Button {
					width: 100%;
				}
			}
		}
	}

	.rdt_Table {
		background-color: ${(props) => props.theme.white};
	}
	@media (max-width: 1879px) {
		.metrics-card {
			.metrics-card-status {
				.metrics-card-title {
					font-size: 14px;
				}
				div {
					font-size: 14px;
				}
			}

			.metrics-card-month,
			.metrics-card-lastmonth,
			.metrics-card-24 {
				.metrics-card-title {
					font-size: 14px;
				}
				.metrics-card-stats {
					font-size: 14px;
				}
			}
		}
	}
	@media (max-width: 1440px) {
		h2 {
			text-align: center;
			font-size: 16px;
		}

		.action-card {
			.action {
				button {
					font-size: 14px;
				}
			}

			.status {
				font-size: 9px;
			}

			.scheme-logs-card {
				.pump-status {
					font-size: 12px;
				}
			}
		}

		.tabs {
			font-size: 14px;
		}

		.metrics-card {
			.metrics-card-status {
				.metrics-card-title {
					padding: 0;
					top: 5px;
					left: 5px;
				}
				div {
					font-size: 14px;
					padding: 0px px 25px;
				}
			}

			.metrics-card-month,
			.metrics-card-lastmonth,
			.metrics-card-24 {
				position: relative;
				justify-content: end;
				.metrics-card-title {
					position: absolute;
					top: 5px;
					left: 5px;
				}
				.metrics-card-stats {
				}
			}
		}
		.table-container {
			padding-top: 45px;

			.datepicker-container {
				button {
					font-size: 12px;
				}
			}
		}
		.settings-container {
			padding-top: 45px;

			h2 {
				top: 125px;
			}

			.actions {
				button {
					width: auto;
					font-size: 14px !important;
				}
			}
		}
	}

	@media (max-width: 1321px) {
		.action-card {
			.action {
				button {
					font-size: 12px;
				}
			}
		}

		.tabs {
			font-size: 12px;
		}

		.metrics-card {
			.metrics-card-status {
				.metrics-card-title {
					font-size: 11px;
				}
				div {
					font-size: 11px;
				}
			}

			.metrics-card-month,
			.metrics-card-lastmonth,
			.metrics-card-24 {
				.metrics-card-title {
					font-size: 11px;
				}
				.metrics-card-stats {
					font-size: 11px;
				}
			}
		}
	}

	@media (max-width: 1131px) {
		.settings-container {
			h2 {
				top: 190px;
			}
		}
	}
	@media (max-width: 1024px) {
		.metrics-card {
			.metrics-card-status {
				.metrics-card-title {
					font-size: 10px;
				}
				div {
					font-size: 10px;
				}
			}

			.metrics-card-month,
			.metrics-card-lastmonth,
			.metrics-card-24 {
				padding: 15px 5px;
				.metrics-card-title {
					font-size: 10px;
				}
				.metrics-card-stats {
					font-size: 10px;
				}
			}
		}
		.general {
			.action-card {
				.pump-status {
					font-size: 8px;
				}
				.status {
					font-size: 12px;
				}
			}
		}
	}
	@media (max-width: 425px) {
		.info-card {
			.tabs {
				.tab-workhistory,
				.tab-settings,
				.tab-commands {
					display: none;
				}
			}
		}

		.metrics-card {
			flex-direction: column;
			height: auto;

			.metrics-card-status {
				width: 100%;

				div {
					padding: 20px 0;
					font-size: 14px;
				}
			}
			.metrics-card-month,
			.metrics-card-lastmonth,
			.metrics-card-24 {
				padding-right: 15px;
				.metrics-card-title {
					font-size: 14px;
				}
				.metrics-card-stats {
					font-size: 14px;
				}
			}
		}

		.general {
			display: flex;
			flex-direction: column;

			.action-card {
				.scheme-card {
					display: none;
				}
				.pump-status {
					width: 100%;
					font-size: 14px;
				}
				.status {
					width: 100%;
				}
			}
			.map {
				.map-container {
					height: 500px;

					.select-layers-button {
						left: 35px;
						top: 105px;
						right: auto;
					}
					.select-layers {
						left: 25px;
						top: 130px;
						right: auto;
					}
				}
			}
		}

		.table-container {
			.table-title {
				top: 280px;
			}
			.datepicker-container {
				display: flex;
				flex-direction: column;
				gap: 15px;
				margin-top: 25px;

				.react-daterange-picker {
					margin-right: 0;
					width: 226px;
				}

				.search-input {
					margin-right: 0;
				}
			}

			.rdt_TableCell {
				padding: 0 5px;
				font-size: 10px;
			}
			.rdt_TableCol {
				padding: 0 5px;
				font-size: 14px;
			}
		}
		.logs-button {
		}
	}
`;
