import useAccessControl from '../../hooks/useAccessControl';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { sendRequest } from '../../api/sendRequest';
import 'ol/ol.css';
import { fromLonLat } from 'ol/proj';
import { RMap, ROSM, RLayerVector, RFeature, RPopup } from 'rlayers';
import { RIcon, RStyle, RStroke } from 'rlayers/style';
import { LineString, Point } from 'ol/geom';
import { useTheme } from 'styled-components';
import locationProbeIcon from './../../svg/Artboard 62.svg';
import locationNodeIcon from './../../svg/iconmonstr-triangle-1.svg';
import { useParams, useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import { DetailsLayout } from './Details.styles';
import { ReactComponent as ProbeSchemeWarning } from './../../svg/pompa-schemat-warning.svg';
import { ReactComponent as ProbeSchemeFull } from './../../svg/pompa-schemat-full.svg';
import { ReactComponent as ProbeSchemeEmpty } from './../../svg/pompa-schemat-empty.svg';
import { Button } from '../../components/Button/Button';
import calculateIconScale from '../../hooks/calculateIconScale';
import { pumpRequest } from '../../api/pumpRequest';
import { Subject, timer } from 'rxjs';
import { switchMap, filter, take, tap } from 'rxjs/operators';
import DetailsLogs from '../../components/Details/Logs/DetailsLogs';
import DetailsSettings from '../../components/Details/Settings/DetailsSettings';
import DetailsCommands from '../../components/Details/Commands/DetailsCommands';
import DetailsWorkHistory from '../../components/Details/WorkHistory/DetailsWorkHistory';
import LoadingThreeDots from '../../components/Loading/LoadingThreeDots';
import PumpStatus from '../../components/Details/General/PumpStatus';
import ProbeLogsRegister from '../../components/Details/ProbeLogsRegister/ProbeLogsRegister';
import locationGatewayIcon from './../../svg/square.svg';

const Details = () => {
	const isAccessVerified = useAccessControl();
	const [mapKey, setMapKey] = useState(Math.random());
	const [drawings, setDrawings] = useState([]);
	const [nodesList, setNodesList] = useState([]);
	const [areGatewayVisible, setGatewayVisible] = useState(true);
	const [arePumpsVisible, setPumpsVisible] = useState(true);
	const [isSelectLayersVisible, setSelectLayersVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isProbeLoading, setIsProbeLoading] = useState(false);
	const [isPumpRunning, setIsPumpRunning] = useState(false);
	const [probeLogsData, setProbeLogsData] = useState([]);
	const [zoomLevel, setZoomLevel] = useState(12);
	const [buttonStatus, setButtonStatus] = useState({});
	const [clickedButtonId, setClickedButtonId] = useState('');
	const [probe, setProbe] = useState([]);
	const [probeSchedules, setProbeSchedules] = useState([]);
	const [probeStats, setProbeStats] = useState([]);
	const [selectedRegion, setSelectedRegion] = useState('');
	const [mapProbe, setMapProbe] = useState([]);
	const [scheme, setScheme] = useState('empty');
	const [activeTab, setActiveTab] = useState('General');
	const [prevProbeRunning, setPrevProbeRunning] = useState(null);
	const theme = useTheme();
	const params = useParams();
	const navigate = useNavigate();
	const [data, setData] = useState(null);
	const [error, setError] = useState('');
	const [shouldCheckPumpStatus, setShouldCheckPumpStatus] = useState(false);
	const [eventId, setEventId] = useState('');
	const [buttonError, setButtonError] = useState('');
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

	const shouldDisplayMap = () => {
		if (!isAccessVerified.isMenuProbeComm && !isMobile) return true;
		if (isAccessVerified.isMenuProbeComm) return true;
		return false;
	};
	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 768);
		};

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);
	useEffect(() => {
		if (!shouldCheckPumpStatus) return;
		const subscription = timer(0, 2000)
			.pipe(
				switchMap(() => {
					console.log('Checking status...');
					return pumpRequest({
						action: 'pump-observable',
						probe_id: params.id,
						event_id: eventId,
					});
				}),
				tap((response) => {
					console.log(
						'Received response before filtering:',
						response
					);
				}),
				filter((response) => {
					if (
						!response.data._data_ ||
						Object.keys(response.data._data_).length === 0
					) {
						return false;
					}
					if (response.data._data_.event === 'pump_unlock_done') {
						setProbe((prevState) => ({
							...prevState,
							active: 1,
						}));
						setMapProbe((prevState) => ({
							...prevState,
							status: 1,
						}));
					} else if (
						response.data._data_.event === 'pump_lock_done'
					) {
						setProbe((prevState) => ({
							...prevState,
							active: 0,
						}));
						setMapProbe((prevState) => ({
							...prevState,
							status: 0,
						}));
					} else if (
						response.data._data_.event === 'pump_start_done'
					) {
						setIsPumpRunning(true);
						setTimeout(() => {
							updatePumpStats();
						}, 1000);
						setTimeout(() => {
							setIsPumpRunning(false);
							updatePumpStats();
						}, 100000);
					} else if (
						response.data._data_.event === 'pump_stop_done'
					) {
						setIsPumpRunning(false);
						setTimeout(() => {
							updatePumpStats();
						}, 1000);
					} else if (
						response.data._data_.event_response === 'noresponse'
					) {
						setButtonStatus({ [clickedButtonId]: 'error' });
					}
					const isDone = response.data._data_.event.includes('done');
					if (isDone) {
						console.log('Status is "done"');

						setMapKey(Math.random());
					}
					return isDone;
				}),
				tap((response) => {
					console.log('Received response after filtering:', response);
				}),
				take(1)
			)
			.subscribe({
				next: () => {
					setData('Pump is done.');
					setIsProbeLoading(false);
					setShouldCheckPumpStatus(false);
				},
				error: (requestError) => {
					setError(requestError);
					console.log(requestError);
					setIsProbeLoading(false);
					setButtonStatus({ [clickedButtonId]: 'error' });
				},
			});

		return () => subscription.unsubscribe();
	}, [shouldCheckPumpStatus, setShouldCheckPumpStatus]);

	const fetchData = async () => {
		setIsLoading(true);

		const getProbeStats = {
			action: 'get-probe-pump-stats',
			probe_id: params.id,
		};

		try {
			const cachedMapProbes = sessionStorage.getItem('mapProbes');
			const cachedMapProbesTimestamp =
				sessionStorage.getItem('mapProbesTimestamp');
			const currentTime = Date.now();
			let mapProbesResponse = cachedMapProbes
				? JSON.parse(cachedMapProbes)
				: null;
			const isCacheValid =
				cachedMapProbes &&
				cachedMapProbesTimestamp &&
				currentTime - parseInt(cachedMapProbesTimestamp) < 3600000;

			if (!isCacheValid) {
				mapProbesResponse = await sendRequest({
					action: 'get-map-probes-all',
				});
				sessionStorage.setItem(
					'mapProbes',
					JSON.stringify(mapProbesResponse)
				);
				sessionStorage.setItem(
					'mapProbesTimestamp',
					currentTime.toString()
				);
			}

			const [
				probeResponse,
				probeSchedulesResponse,
				nodesResponse,
				probeStatsResponse,
			] = await Promise.all([
				sendRequest({ action: 'get-probe', probe_id: params.id }),
				sendRequest({
					action: 'get-probe-schedules',
					probe_id: params.id,
				}),
				sendRequest({ action: 'get-nodes' }),
				sendRequest(getProbeStats),
			]);

			if (probeResponse.data._data_ === false) {
				navigate('/dashboard');
				setIsLoading(false);
				return;
			}

			setProbeStats(probeStatsResponse.data._data_);
			setNodesList(nodesResponse.data._data_);
			const probeData = probeResponse.data._data_;
			setProbe(probeData);
			const mapProbe = mapProbesResponse
				? mapProbesResponse.data._data_.find(
						(probe) => probe.probe_id === params.id
				  )
				: null;
			setMapProbe(mapProbe);

			checkPumpStatus();

			setSelectedRegion(probeData.region_id);

			setProbeSchedules(probeSchedulesResponse.data._data_);
		} catch (error) {
			console.error(error);
		}

		setIsLoading(false);
	};

	const fetchGateways = async () => {
		const getGatewaysRequest = {
			action: 'get-gateways',
			region_id: selectedRegion,
		};

		try {
			const response = await sendRequest(getGatewaysRequest);
			console.log(response);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		if (selectedRegion > 0) {
			fetchGateways();
		}
	}, [selectedRegion]);

	const updateStatus = (activeStatus, probeRunning, error) => {
		setMapProbe((prevState) => ({
			...prevState,
			probe_running: probeRunning,
			active: activeStatus,
			error: error,
		}));
		setProbe((prevState) => ({
			...prevState,
			probe_running: probeRunning,
			active: activeStatus,
			error: error,
		}));
		updatePumpStats();
	};

	const updatePumpStats = async () => {
		const getProbeStats = {
			action: 'get-probe-pump-stats',
			probe_id: params.id,
		};
		try {
			const probeStatsResponse = await sendRequest(getProbeStats);
			setProbeStats(probeStatsResponse.data._data_);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		if (
			prevProbeRunning !== null &&
			prevProbeRunning !== probe.probe_running
		) {
			updatePumpStats();
		}

		setPrevProbeRunning(probe.probe_running);
	}, [probe]);

	const checkPumpStatus = async () => {
		const checkPumpStatus = {
			action: 'get-probe-pump-status',
			probe_id: params.id,
		};
		try {
			const response = await sendRequest(checkPumpStatus);
			const active = response.data._data_.active;
			const probe_running = response.data._data_.probe_running;
			const error = response.data._data_.error;
			updateStatus(active, probe_running, error);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		const intervalId = setInterval(() => {
			checkPumpStatus();
		}, 5000);
		fetchData();
		setProbeLogsData([]);
		return () => {
			clearInterval(intervalId);
		};
	}, [params.id]);

	const iconColor = useMemo(() => {
		if (!mapProbe) {
			console.warn('Warning: mapProbe is undefined.');
			return theme.warning;
		}

		if (mapProbe.error > 0) return theme.danger;

		if (mapProbe.probe_running === undefined) {
			console.warn('Warning: probe_running is undefined in mapProbe');
			return theme.warning;
		}

		switch (mapProbe.probe_running) {
			case 0:
				return theme.blocked;
			case 1:
				return theme.standby;
			case 2:
				return theme.success;
			case 3:
				return theme.warning;
			default:
				return theme.warning;
		}
	}, [mapProbe, theme]);

	const toggleActivation = async (e) => {
		setIsProbeLoading(true);
		const buttonId = e.currentTarget.getAttribute('data-button-id');
		setButtonStatus({ [buttonId]: '' });
		try {
			const pumpStatusResponse = await pumpRequest({
				action: 'pump-status',
				probe_id: params.id,
			});
			setEventId(pumpStatusResponse.data.event_id);
			const subscription = timer(0, 2000)
				.pipe(
					switchMap(() =>
						pumpRequest({
							action: 'pump-observable',
							probe_id: params.id,
							event_id: pumpStatusResponse.data.event_id,
						})
					),
					tap((response) =>
						console.log('Received response:', response)
					),
					filter((response) =>
						response.data._data_.event.includes('done')
					),
					take(1)
				)
				.subscribe(
					async (response) => {
						console.log('Pump observable returned done.');
						if (response.data._data_.event_response == 0) {
							try {
								const disableResponse = await pumpRequest({
									action: 'pump-disable',
									probe_id: params.id,
								});
								setClickedButtonId(buttonId);
								setEventId(disableResponse.data.event_id);
								setShouldCheckPumpStatus(true);
							} catch (error) {
								setButtonStatus({ [buttonId]: 'error' });
								console.error(
									'Error during toggle or disable:',
									error
								);
							}
						} else if (response.data._data_.event_response == 2) {
							try {
								const enableResponse = await pumpRequest({
									action: 'pump-enable',
									probe_id: params.id,
								});
								setClickedButtonId(buttonId);
								setEventId(enableResponse.data.event_id);
								setShouldCheckPumpStatus(true);
							} catch (error) {
								setButtonStatus({ [buttonId]: 'error' });
								console.error(
									'Error during toggle or enable:',
									error
								);
							}
						} else if (
							response.data._data_.event_response === 'noresponse'
						) {
							setButtonStatus({ [buttonId]: 'error' });
							setIsProbeLoading(false);
						}
					},
					(error) => {
						console.error('Error during pump observable:', error);
						setButtonStatus({ [buttonId]: 'error' });
						setIsProbeLoading(false);
					}
				);
			return () => subscription.unsubscribe();
		} catch (error) {
			console.error('Initial pump status request error:', error);
			setButtonStatus({ [buttonId]: 'error' });
			setIsProbeLoading(false);
		}
	};

	const togglePumpStart = async (e) => {
		const buttonId = e.currentTarget.getAttribute('data-button-id');
		setButtonStatus({ [buttonId]: '' });
		const pumpStartRequest = {
			action: 'pump-start',
			probe_id: params.id,
		};
		setIsProbeLoading(true);
		try {
			const response = await pumpRequest(pumpStartRequest);
			setEventId(response.data.event_id);
			setShouldCheckPumpStatus(true);
			setClickedButtonId(buttonId);
		} catch (error) {
			console.error(error);
			setButtonStatus({ [buttonId]: 'error' });
			setIsProbeLoading(false);
		}
	};
	const togglePumpStop = async (e) => {
		const buttonId = e.currentTarget.getAttribute('data-button-id');
		setButtonStatus({ [buttonId]: '' });
		const pumpStopRequest = {
			action: 'pump-stop',
			probe_id: params.id,
		};
		setIsProbeLoading(true);
		try {
			const response = await pumpRequest(pumpStopRequest);
			setEventId(response.data.event_id);
			setShouldCheckPumpStatus(true);
			setClickedButtonId(buttonId);
		} catch (error) {
			console.error(error);
			setButtonStatus({ [buttonId]: 'error' });
			setIsProbeLoading(false);
		}
	};

	const isActiveSchedule = (schedule) => {
		const currentDate = new Date();
		const startDate = new Date(schedule.start);
		const stopDate = new Date(schedule.stop);

		const [hoursNow, minutesNow] = [
			currentDate.getHours(),
			currentDate.getMinutes(),
		];
		const [hoursStart, minutesStart] = schedule.time_start
			.split(':')
			.map(Number);
		const [hoursStop, minutesStop] = schedule.time_stop
			.split(':')
			.map(Number);

		const isWithinDate =
			currentDate >= startDate && currentDate <= stopDate;
		const isWithinTime =
			(hoursNow > hoursStart ||
				(hoursNow === hoursStart && minutesNow >= minutesStart)) &&
			(hoursNow < hoursStop ||
				(hoursNow === hoursStop && minutesNow <= minutesStop));

		return isWithinDate && isWithinTime;
	};

	function formatTime(secondsTotal) {
		const minutes = Math.floor(secondsTotal / 60);
		const seconds = secondsTotal % 60;
		return `${minutes}m ${seconds}s`;
	}

	if (isLoading) return <Loading />;
	return (
		<DetailsLayout>
			<div className="info-card">
				<div className="name-card">
					<div className="name-card-title">
						<h2>{probe.description || probe.probe_id}</h2>
					</div>
				</div>
				<div className="tabs">
					<div
						className={
							activeTab === 'General'
								? 'active tab-general'
								: 'tab-general'
						}
						onClick={() => setActiveTab('General')}>
						Ogólne
					</div>
					<div
						className={
							activeTab === 'WorkHistory'
								? 'active tab-workhistory'
								: 'tab-workhistory'
						}
						onClick={() => setActiveTab('WorkHistory')}>
						Historia pracy
					</div>
					{isAccessVerified.isMenuProbeLogs && (
						<div
							className={
								activeTab === 'Logs'
									? 'active tab-logs'
									: 'tab-logs'
							}
							onClick={() => setActiveTab('Logs')}>
							Logi
						</div>
					)}
					{isAccessVerified.isMenuProbeSettings && (
						<div
							className={
								activeTab === 'Settings'
									? 'active tab-settings'
									: 'tab-settings'
							}
							onClick={() => {
								fetchData();
								setActiveTab('Settings');
							}}>
							Ustawienia
						</div>
					)}
					{isAccessVerified.isMenuProbeComm && (
						<div
							className={
								activeTab === 'Commands'
									? 'active tab-commands'
									: 'tab-commands'
							}
							onClick={() => setActiveTab('Commands')}>
							Komunikacja
						</div>
					)}
				</div>
			</div>
			<div className="metrics-card">
				<PumpStatus probe={probe} />
				<div className="metrics-card-24">
					<div className="metrics-card-title">Dzisiaj</div>
					<div className="metrics-card-stats">
						<div>
							<p>Czas pracy(min):</p>
							<p>Cykli pracy: </p>
							<p>Wypompowana ilość(m³):</p>
						</div>
						<div>
							<p>
								{formatTime(
									probeStats['24h'].sum_pump_cycle_secs
								)}
							</p>
							<p>{probeStats['24h'].cycles}</p>
							<p>{probeStats['24h'].sum_m3}</p>
						</div>
					</div>
				</div>
				<div className="metrics-card-month">
					<div className="metrics-card-title">
						Bieżący <br /> miesiąc
					</div>
					<div className="metrics-card-stats">
						<div>
							<p>Czas pracy(min):</p>
							<p>Cykli pracy: </p>
							<p>Wypompowana ilość(m³):</p>
						</div>
						<div>
							<p>
								{formatTime(
									probeStats.current.sum_pump_cycle_secs
								)}
							</p>
							<p>{probeStats.current.cycles}</p>
							<p>{probeStats.current.sum_m3}</p>
						</div>
					</div>
				</div>
				<div className="metrics-card-lastmonth">
					<div className="metrics-card-title">
						Poprzedni <br /> miesiąc
					</div>
					<div className="metrics-card-stats">
						<div>
							<p>Czas pracy(min):</p>
							<p>Cykli pracy: </p>
							<p>Wypompowana ilość(m³):</p>
						</div>
						<div>
							<p>
								{formatTime(
									probeStats.previous.sum_pump_cycle_secs
								)}
							</p>
							<p>{probeStats.previous.cycles}</p>
							<p>{probeStats.previous.sum_m3}</p>
						</div>
					</div>
				</div>
			</div>
			{activeTab === 'General' && (
				<div className="general">
					<div className="action-card card">
						{isAccessVerified.isMenuProbePumpLock && (
							<div className="action">
								<Button
									data-button-id="toggleActivation"
									className={buttonStatus.toggleActivation}
									disabled={isProbeLoading || isPumpRunning}
									style={{
										cursor:
											isProbeLoading || isPumpRunning
												? 'wait'
												: 'pointer',
										minHeight: '60px',
										width: '100%',
										padding: '10px',
									}}
									onClick={toggleActivation}>
									{isProbeLoading || isPumpRunning ? (
										<LoadingThreeDots />
									) : probe.active === 0 ? (
										'Odblokuj Pompownię'
									) : (
										'Zablokuj Pompownię'
									)}
								</Button>
								<Button
									disabled={isProbeLoading}
									data-button-id="togglePump"
									className={buttonStatus.toggleActivation}
									style={{
										cursor: isProbeLoading
											? 'wait'
											: 'pointer',
										backgroundColor: isPumpRunning
											? theme.danger
											: undefined,
										minHeight: '60px',
										whiteSpace: 'pre-line',
										width: '100%',
										padding: '10px',
									}}
									onClick={
										isPumpRunning
											? togglePumpStop
											: togglePumpStart
									}>
									{isProbeLoading ? (
										<LoadingThreeDots />
									) : isPumpRunning ? (
										'Ręczny Stop'
									) : (
										'Ręczny Start'
									)}
								</Button>
							</div>
						)}
						{buttonError && <p>{buttonError}</p>}
						<div className="scheme-logs-card">
							<div className="card scheme-card">
								{scheme === 'empty' ? (
									<ProbeSchemeEmpty width={'100%'} />
								) : scheme === 'full' ? (
									<ProbeSchemeFull width={'100%'} />
								) : scheme === 'warning' ? (
									<ProbeSchemeWarning width={'100%'} />
								) : null}
							</div>

							<div className="status">
								<div>
									Adres instalacji:
									<p className="bold">{probe.location}</p>
								</div>
								<div>
									Ostatni odczyt:
									<p className="bold">{probe.last_login}</p>
								</div>
								<ol>
									Harmonogramy:
									{probeSchedules &&
									probeSchedules.length > 0 ? (
										probeSchedules.map(
											(schedule, index) => {
												const activeClass =
													isActiveSchedule(schedule)
														? 'active-schedule'
														: '';
												return (
													<p
														key={index}
														className={activeClass}>
														<p className="bold">
															{schedule.start}
															--
															{schedule.stop}
														</p>
														<p>
															{
																schedule.time_start
															}
															-
															{schedule.time_stop}
														</p>
													</p>
												);
											}
										)
									) : (
										<p>Brak</p>
									)}
								</ol>
							</div>
						</div>
						{isAccessVerified.isMenuProbeRegisters && (
							<ProbeLogsRegister
								setScheme={setScheme}
								isProbeLoading={isProbeLoading}
								setProbeLogsData={setProbeLogsData}
								probeLogsData={probeLogsData}
								probeId={params.id}
							/>
						)}
					</div>
					{shouldDisplayMap() && (
						<div className="map card">
							<div className="map-container">
								{isAccessVerified.isMenuProbeComm && (
									<button
										className="select-layers-button"
										onClick={() =>
											setSelectLayersVisible(
												!isSelectLayersVisible
											)
										}>
										&#9776;
									</button>
								)}
								{isSelectLayersVisible && (
									<div className="select-layers">
										<label>
											<input
												type="checkbox"
												checked={arePumpsVisible}
												onChange={(e) =>
													setPumpsVisible(
														e.target.checked
													)
												}
											/>
											Pompownie
										</label>
										<label>
											<input
												type="checkbox"
												checked={areGatewayVisible}
												onChange={(e) =>
													setGatewayVisible(
														e.target.checked
													)
												}
											/>
											Gateway
										</label>
									</div>
								)}
								<RMap
									minZoom={8}
									width={'100%'}
									height={'100%'}
									initial={{
										center: fromLonLat([
											mapProbe && mapProbe.latitude
												? mapProbe.latitude
												: 21.0122,
											mapProbe && mapProbe.longitude
												? mapProbe.longitude
												: 52.2297,
										]),
										zoom: 16,
									}}
									onMoveEnd={(evt) => {
										setZoomLevel(
											evt.target.getView().getZoom()
										);
									}}>
									<ROSM />
									{mapProbe && mapProbe.latitude && (
										<RLayerVector
											zIndex={2}
											visible={arePumpsVisible}
											properties={{ label: 'Pompownie' }}>
											<RFeature
												onPointerEnter={() =>
													(document.body.style.cursor =
														'pointer')
												}
												onPointerLeave={() =>
													(document.body.style.cursor =
														'default')
												}
												onClick={() => {
													navigate(
														`/reports-alarms?probe_id=${mapProbe.probe_id}`
													);
													document.body.style.cursor =
														'default';
												}}
												geometry={
													new Point(
														fromLonLat([
															mapProbe.latitude
																? mapProbe.latitude
																: 54.527717214738786,
															mapProbe.longitude
																? mapProbe.longitude
																: 18.543494734025003,
														])
													)
												}>
												<RStyle>
													<RIcon
														key={iconColor}
														src={locationProbeIcon}
														color={iconColor}
														scale={calculateIconScale(
															1,
															zoomLevel
														)}
														anchor={[0.5, 0.5]}
													/>
												</RStyle>
												<RPopup
													trigger={'hover'}
													className="map-popup">
													<p>
														<strong>
															{mapProbe.probe_info &&
															mapProbe.probe_info
																.probe_name
																? mapProbe
																		.probe_info
																		.probe_name
																: 'Brak Nazwy'}
														</strong>
													</p>
													<p>
														<em>
															{mapProbe.probe_info &&
															mapProbe.probe_info
																.probe_description
																? mapProbe
																		.probe_info
																		.probe_description
																: 'Brak opisu'}
														</em>
													</p>
													<div>
														Harmonogramy:
														{mapProbe.schedules &&
															mapProbe.schedules
																.split(',')
																.map(
																	(
																		schedule,
																		index
																	) => (
																		<p
																			key={
																				index
																			}>
																			{
																				schedule
																			}
																		</p>
																	)
																)}
													</div>
												</RPopup>
											</RFeature>
										</RLayerVector>
									)}
									{probe.gateway_lon && probe.gateway_lat && (
										<>
											<RLayerVector
												visible={areGatewayVisible}
												key={mapKey}
												properties={{
													label: 'Gateway',
												}}>
												<RFeature
													geometry={
														new LineString([
															fromLonLat([
																probe.gateway_lon,
																probe.gateway_lat,
															]),
															fromLonLat([
																mapProbe.latitude,
																mapProbe.longitude,
															]),
														])
													}>
													<RStyle>
														<RStroke
															color={'green'}
															width={1}
														/>
													</RStyle>
												</RFeature>
											</RLayerVector>
											<RLayerVector
												zIndex={1}
												visible={areGatewayVisible}
												properties={{
													label: 'Gateway',
												}}>
												<RFeature
													geometry={
														new Point(
															fromLonLat([
																probe.gateway_lon,
																probe.gateway_lat,
															])
														)
													}>
													<RStyle>
														<RIcon
															scale={calculateIconScale(
																1,
																zoomLevel
															)}
															src={
																locationGatewayIcon
															}
															anchor={[0.5, 0.7]}
														/>
													</RStyle>
													<RPopup
														trigger={'hover'}
														className="map-popup">
														<p>
															<strong>
																{probe.gateway
																	? probe.gateway
																	: 'Brak Nazwy'}
															</strong>
														</p>
													</RPopup>
												</RFeature>
											</RLayerVector>
										</>
									)}
								</RMap>
							</div>
						</div>
					)}
				</div>
			)}
			{activeTab === 'WorkHistory' && <DetailsWorkHistory />}
			{activeTab === 'Logs' && <DetailsLogs />}
			{activeTab === 'Settings' && (
				<DetailsSettings
					probe={probe}
					nodesList={nodesList}
					probeSchedules={probeSchedules}
					setMapProbe={setMapProbe}
				/>
			)}
			{activeTab === 'Commands' && <DetailsCommands probe={probe} />}
		</DetailsLayout>
	);
};

export default Details;
