export const lightTheme = {
	name: "light",
	primary: 'hsl(311, 38%, 28%)',
	darkerprimary: 'hsl(311, 38%, 18%)',
	secondary: "#C6D4FF", //inputy, komórki w tabelach
	background: "#c4ccdd", // tylko tło
	success: "#2bc03f",
	danger: "#ee4242",
	warning: "#969696",
	standby: "#1060eb",
	blocked: "#222222",
	gray: "#727272", // statusy, jakies pojedyncze rzeczy
	slategray: "#5F7B8A",
	white: "#FFFFFF",
	fontcolor: "#5F616E",
	hoverwhite: "#ddd",
	pipe: "rgba(0, 0, 0, 0.80)",
	gatewayline: "green",
	tableheadfontcolor: "#FFFFFF",
	tablebodyfontcolor: "#5F7B8A",
	expandedTableBackgroundColor: "#ddd",
	expandedTheadBackgroundColor: "#f2f2f2",
	expandedOddRowBackgroundColor: "#f9f9f9",
	expandedHoverRowBackgroundColor: "#e6e6e6",
};

export const darkTheme = {
	name: "dark",
	primary: 'hsl(311, 38%, 28%)',
	darkerprimary: 'hsl(311, 38%, 18%)',
	secondary: "#3f3f3f",
	background: "#121212",
	success: "#2bc03f",
	danger: "#ee4242",
	warning: "#969696",
	standby: "#1060eb",
	blocked: "#222222",
	gray: "#727272",
	slategray: "#5F7B8A",
	white: "#282828",
	fontcolor: "#ffffff",
	hoverwhite: "#575757",
	pipe: "rgba(0, 0, 0, 0.5)",
	gatewayline: "green",
	tableheadfontcolor: "#dbd9db",
	tablebodyfontcolor: "#d1cdd0",
	expandedTableBackgroundColor: "#5e5e5e",
	expandedTheadBackgroundColor: "#2f2f2f",
	expandedOddRowBackgroundColor: "#1f1f1f",
	expandedHoverRowBackgroundColor: "#3f3f3f",
};
