import { useState, useEffect } from 'react';
import { ReportsLayout } from '../Reports.style';
import { StyledDataTable } from '../../../components/Table/Table.styles';
import DatePicker from 'react-date-picker';
import LoadingComponent from '../../../components/Table/LoadingComponent';
import { sendRequest } from '../../../api/sendRequest';
import { useNavigate } from 'react-router-dom';
import * as XLSX from "xlsx";
import { Button } from '../../../components/Button/Button';

const ReportsMonth = () => {
	const [loading, setLoading] = useState(true);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [tableData, setTableData] = useState([]);
	const [sortedIsoDates, setSortedIsoDates] = useState([]);
	const [dailyData, setDailyData] = useState({});
	const [searchValue, setSearchValue] = useState(''); 
	const [data, setData] = useState({});
	const navigate = useNavigate();

	useEffect(() => {
		if (!selectedDate) {
		  setTableData([]);
		  setLoading(false);
		  return;
		}
		const fetchData = async () => {
		  setLoading(true);
		  const dateStr = formatDateToISO(selectedDate);
		  const requestData = {
			action: 'get-probes-month-statistics',
			start: dateStr,
		  };
		  const requestDataDaily = {
			action: 'get-probes-month-statistics-daily',
			start: dateStr,
		  };
		  try {
			const responseDaily = await sendRequest(requestDataDaily);
			setDailyData(
			  responseDaily.data._data_.reduce((acc, curr) => {
				acc[curr.probe_id] = curr.m3; 
				return acc;
			  }, {})
			);
			const response = await sendRequest(requestData);
			setData(response.data._data_);
		  } catch (error) {
			console.error('Error fetching data:', error);
		  }
		  setLoading(false);
		};
	  
		fetchData();
	  }, [selectedDate]);


	const processAndSetData = (data) => {
		try {
			const uniqueDates = new Set();
			data.forEach((item) => {
				JSON.parse(item.pump_days).forEach((dateString) => {
					const date = new Date(dateString);
					const isoDate = `${date.getFullYear()}-${(
						date.getMonth() + 1
					)
						.toString()
						.padStart(2, '0')}-${date
						.getDate()
						.toString()
						.padStart(2, '0')}`;
					uniqueDates.add(isoDate);
				});
			});

			const sortedDates = Array.from(uniqueDates).sort();
			setSortedIsoDates(sortedDates);

			const processedData = data.map((probe) => {
				const pumpM3 = JSON.parse(probe.pump_m3);
				const pumpDays = JSON.parse(probe.pump_days);
				const rowData = {
					probeId: probe.probe_id,
					location: probe.location,
					probeName: probe.probe_name,
				};

				sortedDates.forEach((isoDate) => {
					const index = pumpDays.indexOf(isoDate);
					rowData[isoDate] =
						index !== -1 ? parseFloat(pumpM3[index].toFixed(2)) : 0;
				});


				
				const totalM3 = dailyData[probe.probe_id] || 0;
				rowData['Suma'] = totalM3.toFixed(2);

				return rowData;
			});

			setTableData(processedData);
		} catch (error) {
			console.error('Error processing data:', error);
			setTableData([]);
			setSortedIsoDates([]);
		}
	};

	useEffect(() => {
		if (Object.keys(dailyData).length > 0 && Object.keys(data).length > 0) {
		  processAndSetData(data);
		}
	  }, [dailyData, data]);
	  

	const columns = [
		{
			name: 'Nazwa Przepompowni',
			selector: (row) => {
				if (row.probeName && row.probeName !== null) {
					return row.location && row.location !== null
						? `${row.location} (${row.probeName})`
						: row.probeName;
				} else if (row.location && row.location !== null) {
					return row.location;
				} else if (row.probeId && row.probeId !== null) {
					return row.probeId;
				}
				return '';
			},
			grow: 5,
			sortable: true,
		},
		{
			name: 'Suma',
			selector: (row) => row['Suma'],
			sortable: true,
		},
		...sortedIsoDates
			.map((isoDate) => {
				const [year, month, day] = isoDate.split('-');
				return {
					displayDate: `${day}.${month}`,
					isoDate,
				};
			})
			.map(({ displayDate, isoDate }) => ({
				name: displayDate,
				selector: (row) => row[isoDate],
				minWidth: '30px',
				sortable: true,
			})),
	];
	const customStyles = {
		headCells: {
			style: {
				fontSize: '12px !important',
				paddingLeft: '3px !important',
				paddingRight: '3px !important',
			},
		},
		cells: {
			style: {
				fontSize: '12px !important',
				paddingLeft: '3px !important',
				paddingRight: '3px !important',
				justifyContent: 'center !important',
				cursor: 'pointer',
			},
		},
	};

	const formatDateToISO = (date) => {
		const year = date.getFullYear();
		const month = date.getMonth() + 1;
		const day = 1;

		const monthFormatted = month < 10 ? `0${month}` : month;

		return `${year}-${monthFormatted}-01`;
	};

	const handleDateChange = (date) => {
		if (!date) {
			setSelectedDate(null);
			return;
		}

		const firstDayOfMonth = new Date(
			date.getFullYear(),
			date.getMonth(),
			1
		);
		setSelectedDate(firstDayOfMonth);
	};

	const exportToExcel = () => {
		const modifiedTableData = tableData.map(({ probeName, ...rest }) => rest);
		const ws = XLSX.utils.json_to_sheet(modifiedTableData);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
		XLSX.writeFile(wb, "reportAlarms.xlsx");
	};
	

	return (
		<ReportsLayout>
			<h2 className="page-title">Zestawienie Miesięczne (m³)</h2>
			<div className="table-container">
				<StyledDataTable
					columns={columns}
					data={tableData.filter(row =>
						Object.values(row).some(value => 
							value !== null && value.toString().toLowerCase().includes(searchValue.toLowerCase())
						)
					)}					
					paginationComponentOptions={{
						rowsPerPageText: 'Wiersze na stronę:',
					}}
					customStyles={customStyles}
					progressPending={loading}
					noDataComponent="Brak danych spełniających kryteria"
					progressComponent={<LoadingComponent />}
					onRowClicked={(row) => {
						navigate(`/details/${row.probeId}`);
					}}
				/>
				<div className="datepicker-container">
					<DatePicker
						clearIcon={null}
						maxDetail="year"
						maxDate={new Date()}
						onChange={handleDateChange}
						value={selectedDate}
						showLeadingZeros={true}
						format="MM/yyyy"
						view="year"
						minDetail="year"
					/>
					<input className='search' placeholder='Szukaj...' value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
					<Button
						style={{ padding: "5px 40px" }}
						className="button"
						onClick={exportToExcel}>
						Eksportuj do Excela
					</Button>
				</div>
			</div>
		</ReportsLayout>
	);
};

export default ReportsMonth;
